
























































































































































































































































































import Component, { mixins } from 'vue-class-component';

import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { Getter, namespace } from 'vuex-class';
import {
  IFileDownloads,
  ILogs,
  ILogsGraph,
  IPaginate,
  ISenderID,
  IUser,
} from '@/types/types';
import { dateFormat, truncateContent, loadWidget } from '@/utils/helpers';
import { Watch } from 'vue-property-decorator';
import moment from 'moment';
import { debounce } from 'lodash';

const logsModule = namespace('log');
const smsModule = namespace('sms');
const usersModule = namespace('users');

@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    DoubleDatePicker: loadWidget('widgets/DoubleDatePicker'),
  },
  filters: {
    dateFormat,
    truncateContent,
  },
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @logsModule.Getter('getPagination') paginate!: IPaginate & { limit: number };
  @logsModule.Getter('getAllLogs') logs!: ILogs[];
  @logsModule.Getter('getLogsGraph') logsGraph!: Array<ILogsGraph>;
  @smsModule.Getter('getSenderIDList') approvedSenderIDlist!: Array<
    ISenderID & { id: number; slug: string; uuid: string }
  >;
  @usersModule.Getter('getCustomerDetails') customerDetails!: IUser;

  menu = false;
  renderChart = false;
  options: IPaginate = {
    itemsPerPage: 15,
    page: 1,
    total: 0,
  };
  breadcrumbs = [{ text: 'Downloads', href: '/reports/downloads' }];
  headers: Array<{ text: string; value: string; align?: string }> = [
    {
      text: 'Date',
      value: 'updatedAt',
    },
    {
      text: 'Status',
      value: 'status',
    },

    {
      text: 'Type',
      value: 'type',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'File Size',
      value: 'fileSize',
    },
    { text: 'Action', value: 'action', align: 'center' },
  ];
  searchTerm = '';
  senderIdFilters: ISenderID[] = [];
  selectedFileId: string | null = null;
  fileExtension = '';
  statusColors: { [key: string]: { bg: string; text: string } } = {
    'IN-PROGRESS': {
      bg: 'rgba(253, 183, 132, 0.41)',
      text: 'rgb(202, 133, 0)',
    },
    FAILED: {
      bg: 'rgba(251, 107, 92, 0.41)',
      text: 'rgb(240, 43, 22)',
    },
    COMPLETED: { bg: 'rgba(65, 253, 77, 0.41)', text: 'rgb(7, 143, 60)' },
  };

  series: Array<{ name: string; data: number[] }> = [];

  dateFilter = '';
  page = 1;
  dataLoaderPlaceholder = 'Loading data ...';

  handleOnSearchTextChange = debounce(this.searchText, 1000);

  searchText(text: string): void {
    this.$store.dispatch('log/listAllFileDownloads', {
      page: this.page,
      limit: this.options?.itemsPerPage,
      query: `${this.dateFilter}${text ? '&searchTerm=' + text : ''}${
        this.senderIdFilters.length > 0
          ? '&filters=' +
            this.senderIdFilters
              .map((item) => item.name?.toLowerCase())
              .join(',')
          : ''
      }`,
      subAccountId: this.$route.params.id,
    });
  }

  @Watch('senderIdFilters')
  onSenderIdFiltersChange(filters: ISenderID[]): void {
    this.$store.dispatch('log/listAllFileDownloads', {
      page: this.page,
      limit: this.options?.itemsPerPage,
      query: `${this.dateFilter}${
        this.searchTerm ? '&searchTerm=' + this.searchTerm : ''
      }${
        filters.length > 0
          ? '&filters=' +
            filters.map((item) => item.name?.toLowerCase()).join(',')
          : ''
      }`,
      subAccountId: this.$route.params.id,
    });
  }

  @Watch('searchTerm')
  onSearchTermChange(term: string): void {
    this.handleOnSearchTextChange(term);
  }

  @Watch('page')
  onOptionsChange(page: number): void {
    this.$store.dispatch('log/listAllFileDownloads', {
      page: page ?? 1,
      limit: this.paginate.limit ?? 15,
      query: `${this.dateFilter}${
        this.searchTerm ? '&searchTerm=' + this.searchTerm : ''
      }`,
      subAccountId: this.$route.params.id,
    });
  }

  filterLogsByDate(date: string): void {
    this.page = 1;
    this.dateFilter = `&date=${date}`;
    this.$store.dispatch('log/listAllFileDownloads', {
      page: this.page,
      limit: this.paginate.limit,
      query: `${this.dateFilter}${
        this.searchTerm ? '&searchTerm=' + this.searchTerm : ''
      }`,
      subAccountId: this.$route.params.id,
    });
  }

  exportFile(file: IFileDownloads & { _id: string }): void {
    this.selectedFileId = file._id;
    const a = document.createElement('a');
    a.download = file.name;
    a.href = file.downloadUrl;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  confirmFileDelete(id: string | number): void {
    this.selectedFileId = id as string;
    this.open('delete');
  }

  deleteFile(): void {
    if (this.selectedFileId !== null) {
      this.$store.dispatch('log/deleteDownloadedFile', {
        id: this.selectedFileId,
        query: `${this.dateFilter}${
          this.searchTerm ? '&searchTerm=' + this.searchTerm : ''
        }`,
        subAccountId: this.$route.params.id,
      });
      return;
    }
    this.close({
      state: false,
      idx: 'delete',
    });
  }

  created(): void {
    this.page = 1;
    this.dateFilter = `&date=${moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`;
    this.$store.dispatch('log/listAllFileDownloads', {
      page: this.page,
      limit: 15,
      query: this.dateFilter,
      subAccountId: this.$route.params.id,
    });

    if (this.$route.params.id) {
      this.$store.dispatch('users/customerDetails', {
        accountId: this.$route.params.id,
      });
    }
  }
}
